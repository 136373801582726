<template>
  <detail-layout-with-pic
    header="Treatments"
    text-col-classes="mb-6 sm:mb-14 md:mb-2"
  >
    <template v-slot:text-col>
      <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
        What is a neurological condition?
      </h2>
      <p>Neurological problems can arise from damage to the nervous system.</p>
      <p>
        The system is made up of the brain and spinal cord, radiating out to our
        peripheral nerves and muscles.
      </p>
      <p>Damage to this system can happen as a result of disease or injury.</p>
      <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
        What is neurological physiotherapy?
      </h2>
      <p>
        Neurological Physiotherapy is an approach to treatment for individuals
        who have movement problems, usually caused by neurological conditions or
        events.
      </p>
      <p>
        It is, however, not limited to these and can be applied to most movement
        challenges.
      </p>
      <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
        Who is a neurological physiotherapist?
      </h2>
      <p>
        A neurological physiotherapist will have had post graduate training to
        specialise in neurological treatments and management.
      </p>
      <p>
        This training should continue throughout their professional life, with
        constant improvement and updating of knowledge.
      </p>
      <p>
        A neurological physio will have knowledge about how our nervous system
        functions to produce both normal and abnormal movement.
      </p>
      <p>
        They will have techniques, management advice and activities to help
        movement change towards being more normal.
      </p>
      <p>
        Movement which can be used easily and efficiently in everyday life, if
        possible.
      </p>
      <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
        What Will treatment involve?
      </h2>
      <p>
        Most of the treatments with Balance Neuro Physiotherapy will occur in
        your own home. We will, however, often meet people in the gym, out in a
        public area, swimming or at work.
      </p>
      <p>
        Your treatment will involve some activities with the physiotherapist,
        you will have activities to practise to reinforce each treatment session
        and also enhance your posture, strength and mobility.
      </p>
      <p>Such as:</p>
      <ul class="mb-5">
        <li>Practice of functional tasks</li>
        <li>Core stability improvement</li>
        <li>Strengthening exercises and activities</li>
        <li>Mobilisation of soft tissues and joints</li>
        <li>Gait reeducation and practice</li>
        <li>Balance exercises</li>
        <li>Postural re-education and management</li>
        <li>Practising functional activities</li>
        <li>Improving your exercise tolerance and overall fitness levels</li>
        <li>Practicing to improve your confidence.</li>
      </ul>
    </template>
    <template v-slot:pic-col>
      <img
        class="object-cover object-center rounded mb-10"
        alt="Patient cutting grass"
        src="../assets/images/lunging.jpg"
        width="474"
        height="593"
      />
      <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
        How will they assess me?
      </h2>
      <p>
        A neurological physio will have specialist skills in the analysis of
        movement.
      </p>
      <p>
        They will achieve this by observing, palpation and feeling how an
        individual may move. Following an assessment of movement, a plan should
        be discussed and agreed.
      </p>
      <p>This will be aimed at improving movement and posture.</p>
      <p>
        Hopefully improving how an individual may manage and cope with daily
        life.
      </p>
      <p>
        It is highly likely that an activity plan will need to be undertaken by
        the individual/ carer in between physio contacts in order to change
        movement.
      </p>
      <p>
        The neuro physio will have specialist handling skills to make movement
        easier, helping to make it possible to have the best possible function,
        independence and quality of life.
      </p>
      <p>
        A neuro physio should also be aware of, and may assist with, families
        and carers for the individual being assessed.
      </p>
      <p>
        They will be aware of possible carer stresses, and may be able to risk
        assess, advise and delegate certain activities or modifications to make
        life easier.
      </p>
      <p>
        A neuro physio will also be interested in how an individual manages to
        function in certain environments and at different times of the day.
      </p>
      <p>
        Neuro physios are also able to treat other joint or movement problems
        arising from repetitive strain, sports injury, postural problems, and
        other origins.
      </p>
      <p class="mb-1">
        They will apply their expertise in movement analysis, their knowledge of
        postural control, muscle function and joint mobilisation to improve the
        overall function of an individual.
      </p>
    </template>
    <div class="flex md:flex-row flex-col justify-between -mx-4">
      <div
        class="w-full md:w-1/2 px-4 flex flex-col md:items-start mb-3 md:mb-0"
      >
        <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
          What is a Vestibular condition?
        </h2>
        <p>
          A vestibular condition can occur when there is a problem with the
          vestibular system, a tiny complex of tubes and specialised cells that
          sit at the level of your ears on either side and detect movement, so
          your brain knows where the body is in space and whether it is moving
          and in what direction. They sit next to the hearing system, the
          cochlea. Damage can occur for all sorts of reasons and for many
          vestibular problems physiotherapy is the most effective treatment.
          This can sometimes be a very quick treatment such as a manoeuvre to
          reposition tiny crystals which become dislodged in a condition called
          BPPV or longer physiotherapy treatments as part of Vestibular
          Rehabilitation Therapy (VTR).
        </p>
      </div>
      <div class="w-full md:w-1/2 px-4 flex flex-col md:items-start">
        <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
          What is vestibular rehabilitation?
        </h2>
        <p>
          Vestibular rehabilitation is an exercise-based program, designed by a
          specially-trained vestibular physical therapist, to improve balance
          and reduce problems related to dizziness. Dizziness is described in
          many ways, for example, feelings of unsteadiness; wooziness (swimming
          feeling in head); light-headedness; feelings of passing out;
          sensations of moving; spinning floating; swaying; tilting or whirling
          (sensations known as vertigo). Dizziness, imbalance and vertigo can
          have various causes for example: benign paroxysmal positional vertigo
          (BPPV), labyrinthitis or vestibular neuritis causing vestibular
          hypofunction, Meniere's disease, vestibular migraine and persistent
          postural perceptual disorder (PPPD).
        </p>
      </div>
    </div>
  </detail-layout-with-pic>
</template>

<script>
import DetailLayoutWithPic from "../layouts/DetailLayoutWithPic.vue";

export default {
  name: "Treatments",
  components: {
    "detail-layout-with-pic": DetailLayoutWithPic,
  },
};
</script>
